
import { computed, defineComponent } from "vue";
import { useStore } from 'vuex';
import NavigationRoutes from './NavigationRoutes';

export default defineComponent({
  name: "Sidebar",
  setup() {
    const menuSelect = (key, keyPath) => {
      console.log(key, keyPath)
    };
    const store = useStore();
    const roles = computed(() => store.getters.roles);
    const avatar = computed(() => store.getters.avatar);
    const role = roles.value[0];
    console.log('roles', roles.value[0], avatar.value);
    return {
      menuSelect,
      role,
    }
  },
  data() {
    return {
      routes_record: NavigationRoutes.routes,
      logo_url: '/logo.png',
      fit: '',
      activeMenuIndex: this.$route.path,
    }
  },
});
