export default {
    root: {
        name: '/',
        displayName: 'navigationRoutes.home',
    },
    routes: [
        {
            name: 'goods',
            path: '/admin/goods',
            displayName: 'menu.goods',
            meta: {
                title: '产品',
                icon: 'el-icon-message',
            }
        },
        {
            name: 'brand',
            path: '/admin/brand',
            displayName: 'menu.brand',
            disabled: false,
            meta: {
                title: '品牌',
                icon: 'el-icon-message',
            },
        },
        {
            name: 'model',
            path: '/admin/model',
            displayName: 'menu.model',
            disabled: false,
            meta: {
                title: '型号',
                icon: 'el-icon-message',
            },
        },
    ],
}
