
import {computed, defineComponent} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Sidebar from '@/components/sidebar/Sidebar.vue';
import { ArrowDown } from '@element-plus/icons';

export default defineComponent({
  name: "AdminLayout",
  components: {
    Sidebar, ArrowDown
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const avatar = computed(() => store.getters.avatar);
    const logoutAction = async () => {
      await store.dispatch('user/LogOut');
      await router.push('/auth/login');
    };
    return {
      avatar,
      logoutAction,
    };
  },
  data() {
  },
  methods: {
    async logOut() {
      await this.logoutAction();
    },
    userSet() {
      this.$router.push('/admin/settings');
    },
  }
});
