
import {defineComponent} from "vue";

export default defineComponent({
  name: "HomeViews",
  data() {
    return {
      submit_loading: false,
      formQuery: {
        code: '',
      },
      rules: {
        code: [
          {
            required: true,
            message: '请输入编码',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  methods: {
    onQuery() {
      this.$refs['formQuery'].validate(async(valid) => {
        if (valid) {
          this.submit_loading = true;
          await this.$router.push({
            path: '/q/res',
            query: {
              'q': this.formQuery.code
            }
          });
          this.submit_loading = false;
        } else {
          return false;
        }
      });
      return false;
    },
  },
});
