import axios from "axios";
import {ElMessage, ElMessageBox} from "element-plus";
import store from '@/store';
import {getToken} from "@/utils/auth";

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 5000
});

// Request interceptors
service.interceptors.request.use(
    (config) => {
        // Add X-Access-Token header to every request, you can add other custom headers here
        if (store.getters.token) {
            config.headers['authorization'] = 'Bearer ' + getToken();
        }
        return config;
    },
    (error) => {
        Promise.reject(error)
    }
);

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    (response) => {
        const res = response.data

        // if the custom code is not 200, it is judged as an error.
        // @ts-ignore
        if (res.code !== 200) {
            // @ts-ignore
            ElMessage({
                // @ts-ignore
                message: res.message || 'Error',
                type: 'error',
                duration: 5 * 1000,
            })

            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            // @ts-ignore
            if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
                // to re-login
                ElMessageBox.confirm(
                    '您已登出，可以取消停留在此页面上，或者再次登录',
                    '重新登录',
                    {
                        confirmButtonText: '重新登录',
                        cancelButtonText: '离开',
                        type: 'warning',
                    }
                ).then(() => {
                    store.dispatch('user/resetToken').then(() => {
                        location.reload()
                    })
                })
            }
            // @ts-ignore
            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res
        }
    },
    (error) => {
        console.log('err' + error) // for debug
        // @ts-ignore
        ElMessage({
            message: error.message,
            type: 'error',
            duration: 5 * 1000,
        })
        return Promise.reject(error)
    }
)

export default service
