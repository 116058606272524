// @ts-ignore
import request from "@/utils/request";

export const getUserInfo = (params: any) =>
    request({
        url: '/user/info',
        method: 'get',
        params
    });

export const userList = (params: any) =>
    request({
        url: '/user/list',
        method: 'get',
        params,
    });

export const userCount = (params: any) =>
    request({
        url: '/user/statistics',
        method: 'get',
        params,
    });

export const userGetOne = (params: any) =>
    request({
        url: '/user/q',
        method: 'get',
        params,
    });

export const userAdd = (data: any) =>
    request({
        url: '/user/add',
        method: 'post',
        data,
    });

export const userEdit = (data: any) =>
    request({
        url: '/user/edit',
        method: 'post',
        data,
    });

export const userDel = (data: any) =>
    request({
        url: '/user/del',
        method: 'post',
        data,
    });

export const userSetting = (data: any) =>
    request({
        url: '/user/setting',
        method: 'post',
        data,
    });

export const selfInfo = (params: any) =>
    request({
        url: '/user/me',
        method: 'get',
        params,
    });

export const login = (data: any) =>
    request({
        url: '/auth/login',
        method: 'post',
        data
    });

export const logout = () =>
    request({
        url: '/auth/logout',
        method: 'post'
    });

export const brandList = (params: any) =>
    request({
        url: '/brand/list',
        method: 'get',
        params,
    });

export const brandRecord = (data: any) =>
    request({
        url: '/brand/add',
        method: 'post',
        data,
    });

export const brandEdit = (data: any) =>
    request({
        url: '/brand/edit',
        method: 'post',
        data,
    });

export const brandAll = (params: any) =>
    request({
        url: '/brand/all',
        method: 'get',
        params,
    });

export const brandCount = (params: any) =>
    request({
        url: '/brand/statistics',
        method: 'get',
        params,
    });

export const brandGetOne = (params: any) =>
    request({
        url: '/brand/q',
        method: 'get',
        params,
    });

export const productList = (params: any) =>
    request({
        url: '/model/list',
        method: 'get',
        params,
    });

export const productCount = (params: any) =>
    request({
        url: '/model/statistics',
        method: 'get',
        params,
    });

export const productRecord = (data: any) =>
    request({
        url: '/model/add',
        method: 'post',
        data,
    });

export const productEdit = (data: any) =>
    request({
        url: '/model/edit',
        method: 'post',
        data,
    });

export const productFind = (params: any) =>
    request({
        url: '/model/find',
        method: 'get',
        params,
    });

export const productGetOne = (params: any) =>
    request({
        url: '/model/q',
        method: 'get',
        params,
    });

export const goodsList = (params: any) =>
    request({
        url: '/instrument/list',
        method: 'get',
        params,
    });

export const goodsCount = (params: any) =>
    request({
        url: '/instrument/statistics',
        method: 'get',
        params,
    });

export const goodsRecord = (data: any) =>
    request({
        url: '/instrument/add',
        method: 'post',
        data,
    });

export const goodsGetOne = (params: any) =>
    request({
        url: '/instrument/q',
        method: 'get',
        params,
    });

export const goodsEdit = (data: any) =>
    request({
        url: '/instrument/edit',
        method: 'post',
        data,
    });

export const goodsDel = (data: any) =>
    request({
        url: '/instrument/del',
        method: 'post',
        data,
    });

export const goodsQuery = (params: any) =>
    request({
        url: '/instrument/query',
        method: 'get',
        params,
    });
