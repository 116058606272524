import router from "@/router";
import NProgress from "nprogress";
import 'nprogress/nprogress.css';
import {getToken} from "@/utils/auth";
import {ElMessage} from "element-plus";
import {RouteLocationNormalized} from "vue-router";
import user from "@/store/modules/user";
import store from './store'
// import {getUserInfo} from "@/api/musical";

NProgress.configure({ showSpinner: false });

const whiteList = ['/auth/login', '/', '/q/res'];   // 允许不检查的列表

router.beforeEach(async (to: RouteLocationNormalized, _: RouteLocationNormalized, next: any) => {
    NProgress.start();  // 开始一个进度条

    const hasToken = getToken();
    if (hasToken) { // 用户已经登录惹
        if (to.path === '/auth/login') { // 如果是登录页面，就跳转到首页
            next({ path: '/admin/goods' });
            NProgress.done();   // 打完收工
        } else {
            if (user.state.roles.length === 0) {    // 但是没有获得权限信息
                try {
                    await store.dispatch('user/GetUserInfo');
                    next({ ...to, replace: true });
                } catch (err) {
                    await store.dispatch('user/resetToken');
                    ElMessage.error(err.toString() || 'Has Error');
                    next(`/auth/login?redirect=${to.path}`);
                    NProgress.done();
                }
            } else {
                next();
            }
        }
    } else {    // 没有登录的可人儿
        if (whiteList.indexOf(to.path) !== -1) {    // 不用登录就可以访问的页面，就随他去吧
            next();
        } else {
            next(`/auth/login?redirect=${to.path}`);
            NProgress.done();
        }
    }
});

router.afterEach((to: RouteLocationNormalized) => {
    NProgress.done();   // 打完收工
    // @ts-ignore
    document.title = to.meta.title || to.name;
});
