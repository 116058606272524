import { createRouter, createWebHistory } from 'vue-router'
import AdminLayout from '@/layout/admin-layout.vue';
import AppLayout from '@/layout/app-layout.vue';
import AuthLayout from '@/layout/auth-layout.vue';
import Home from '@/views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Flora弗洛拉乐器',
    },
  },
  {
    path: '/q',
    name: 'HomeQ',
    component: AppLayout,
    children: [
      {
        name: 'Query',  // 查询界面
        path: 'res',
        component: () => import('@/views/app/query/QueryViews.vue'),
        meta: {
          title: 'Flora弗洛拉乐器',
        },
      }
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'Login',  // 登录界面
        path: 'login',
        component: () => import('@/views/auth/login/Login.vue'),
        meta: {
          title: '登录',
        },
      }
    ],
  },
  {
    name: 'admin',
    path: '/admin',
    component: AdminLayout,
    children: [
      {
        name: 'Dashboard',
        path: 'dashboard',
        component: () => import('@/views/admin/dashboard/Dashboard.vue'),
        meta: {
          title: '总览',
        },
      },
      {
        name: 'Brand',
        path: 'brand',
        component: () => import('@/views/admin/brand/BrandViews.vue'),
        meta: {
          title: '品牌',
        },
      },
      {
        name: 'addBrand',
        path: 'brand/add',
        component: () => import('@/views/admin/brand/BrandAdd.vue'),
        meta: {
          title: '品牌-新增',
        },
      },
      {
        name: 'editBrand',
        path: 'brand/edit',
        component: () => import('@/views/admin/brand/BrandEdit.vue'),
        meta: {
          title: '品牌-编辑',
        },
      },
      {
        name: 'Model',
        path: 'model',
        component: () => import('@/views/admin/product/ProductViews.vue'),
        meta: {
          title: '型号',
        },
      },
      {
        name: 'addModel',
        path: 'model/add',
        component: () => import('@/views/admin/product/ProductAdd.vue'),
        meta: {
          title: '型号-新增',
        },
      },
      {
        name: 'editModel',
        path: 'model/edit',
        component: () => import('@/views/admin/product/ProductEdit.vue'),
        meta: {
          title: '型号-编辑',
        },
      },
      {
        name: 'Goods',
        path: 'goods',
        component: () => import('@/views/admin/goods/GoodsViews.vue'),
        meta: {
          title: '产品',
        },
      },
      {
        name: 'addGoods',
        path: 'goods/add',
        component: () => import('@/views/admin/goods/GoodsAdd.vue'),
        meta: {
          title: '产品-新增',
        },
      },
      {
        name: 'editGoods',
        path: 'goods/edit',
        component: () => import('@/views/admin/goods/GoodsEdit.vue'),
        meta: {
          title: '产品-编辑',
        },
      },
      {
        name: 'Users',
        path: 'users',
        component: () => import('@/views/admin/users/usersViews.vue'),
        meta: {
          title: '用户',
        },
      },
      {
        name: 'addUsers',
        path: 'users/add',
        component: () => import('@/views/admin/users/usersAdd.vue'),
        meta: {
          title: '用户-新增',
        },
      },
      {
        name: 'editUsers',
        path: 'users/edit',
        component: () => import('@/views/admin/users/usersEdit.vue'),
        meta: {
          title: '用户-编辑',
        },
      },
      {
        name: 'usersSettings',
        path: 'settings',
        component: () => import('@/views/admin/users/usersSettings.vue'),
        meta: {
          title: '设置',
        },
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
