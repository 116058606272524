import {login, logout, getUserInfo} from "@/api/musical";
import {getToken, setToken, removeToken} from "@/utils/auth";

const getDefaultState = () => {
    return {
        token: getToken(),
        name: '',
        avatar: '',
        introduction: '',
        roles: [],
    }
};

const state = getDefaultState();

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token;
    },
    SET_NAME: (state, name) => {
        state.name = name;
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar;
    },
    SET_ROLES: (state, roles: string[]) => {
        state.roles = roles;
    },
    SET_INTRODUCTION : (state, introduction: string) => {
        state.introduction = introduction;
    }
}

const actions = {
    // @ts-ignore
    async Login({ commit }, userInfo: { username: string, password: string }) {
        let username = userInfo.username;
        const password = userInfo.password;
        username = username.trim();

        const { data } = await login({ username, password });
        // @ts-ignore
        setToken(data.auth_token);
        // @ts-ignore
        commit('SET_TOKEN', data.auth_token);
        return data;
    },
    async LogOut({ commit }) {
        if (this.token === '') {
            throw Error('LogOut: token is undefined!');
        }
        await logout();
        removeToken();
        commit('SET_TOKEN', '');
        commit('SET_ROLES', []);
    },
    async GetUserInfo({ commit, state }) {
        if (state.token === '') {
            throw Error('GetUserInfo: token is undefined!');
        }
        const { data } = await getUserInfo({ /* Your params here */ })
        if (!data) {
            throw Error('Verification failed, please Login again.');
        }
        // @ts-ignore
        const { roles, name, avatar, introduction } = data.user;
        // roles must be a non-empty array
        if (!roles || roles.length <= 0) {
            throw Error('GetUserInfo: roles must be a non-null array!');
        }
        commit('SET_ROLES', roles);
        commit('SET_NAME', name);
        commit('SET_AVATAR', avatar);
        commit('SET_INTRODUCTION', introduction);
    },
    resetToken({ commit }) {
        removeToken();
        commit('SET_TOKEN', '');
        commit('SET_ROLES', '');
    },
    setAvatar({ commit }, avatar_str) {
        commit('SET_AVATAR', avatar_str);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
